import React from 'react';
import styled from 'styled-components';
import { colors } from '../../components/Home/v2/StyledComponents';
import { H2, H3 } from './style';

const ColorContainer = styled.div`
  width: 250px;
  height: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: ${props => props.color};
`;

const ColorText = styled.span`
  color: ${props => props.invertedText ? colors.navy : colors.white};
  margin: 2px;
`;

const ColorTextHover = styled.span`
  position: absolute;
  width: 250px;
  height: 180px;
  padding: 10px;
  top: 0px;
  left: 0px;
  opacity: 0;
  &:hover {
    opacity: 1;
    color: ${props => props.invertedText ? colors.navy : colors.white};
    margin: 2px;
  }
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Color = (color, name, descriptionText, invertedText = false) => (
  <div style={{ width: '270px', position: 'relative' }}>
    <ColorContainer color={color} >
      <ColorText invertedText={invertedText}>{color}</ColorText>
      <ColorText invertedText={invertedText}>{name}</ColorText>
    </ColorContainer>
    <ColorTextHover invertedText={invertedText}>{descriptionText}</ColorTextHover>
  </div>
);

const Colors = () => (
  <div>
    <H2>Couleurs</H2>

    <H3>Couleurs principales</H3>
    <ColorsContainer>
      {Color(colors.navy, 'Bleu marine', 'Couleur principale : texte, bordure, icônes, logos, footer')}
      {Color(colors.lightGrey, 'Gris clair',
        'Couleur des bordures des menus deroulants', true)}
      {Color(colors.white, 'Blanc',
        'Couleur de fond des blocks, des textes sur fond Bleu marine', true)}
    </ColorsContainer>
  </div>
);

export default Colors;
