import React from 'react';
import styled from 'styled-components';
import { H2, H3 } from './style';
import { Button, colors } from '../../components/Home/v2/StyledComponents';

const ButtonContainer = styled.span`
  margin: 20px;
`;

const ButtonsContainer = styled.div`
  margin: 0px -20px;
`;

const TextLink = styled.a`
  color: ${colors.navy};
  text-decoration: underline;
  cursor: pointer;
`;

const Buttons = () => (
  <div>
    <H2>Boutons</H2>
    <H3>Normaux</H3>
    <ButtonsContainer>
      <ButtonContainer>
        <Button>Bouton</Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button navy>Bouton</Button>
      </ButtonContainer>
    </ButtonsContainer>

    <H3>Lien</H3>
    <TextLink>Lien</TextLink>
  </div>
);

export default Buttons;
