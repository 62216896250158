import React from 'react';
import styled from 'styled-components';
import { H2, H3, H4 } from './style';
import { colors, Row } from '../../components/Home/v2/StyledComponents';
import Dots from '../../components/Home/v2/Dots';

import arrowLeft from '../../assets/headers/arrow.white.left.png';
import arrowRight from '../../assets/headers/arrow.white.right.png';
import arrowLeftNavy from '../../assets/theyLoveTilli/arrow.left.navy.svg';
import arrowRightNavy from '../../assets/theyLoveTilli/arrow.right.navy.svg';
import LRightUp from '../../assets/whyTilli/L.reversed.white.svg';
import LLeftDown from '../../assets/whyTilli/L.white.svg';

const StyledRow = styled(Row)`
  justify-content: space-between;
  margin: inherit;
  width: ${props => props.width ? props.width : '500px'};
`;

const Container = styled.div`
  background-color: ${props => props.invertedColor ? colors.navy : colors.white};
  width: fit-content;
  padding: 5px;
  margin: 30px 0px;
`;

const Img = styled.img`
  width: 50px;
  padding: ${props => props.left ? '0px 40px 0px 0px' : '0px 0px 0px 40px'};
`;

const LeftSide = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50%;
  width: 25%;
`;

const RightSide = styled.div`
  height: 50%;
  width: 25%;
  margin-bottom: -4px;
`;

const Elements = () => (
  <div>
    <H2>Éléments graphiques</H2>

    <H3>Flèches</H3>
    <H4>Flèches defilant les images</H4>
    <StyledRow>
      <Container invertedColor>
        <Img left src={arrowLeft} alt={'flèche blanche gauche'} />
        <Img right src={arrowRight} alt={'flèche blanche droite'} />
      </Container>
      <Container>
        <Img left src={arrowLeftNavy} alt={'flèche bleu marine gauche'} />
        <Img right src={arrowRightNavy} alt={'flèche bleu marine droite'} />
      </Container>
    </StyledRow>

    <H4>Autres flèches</H4>
    <Container invertedColor>
      <LeftSide><Img right src={LRightUp} alt={'flèche en L blanche droite'} /></LeftSide>
      <RightSide><Img left src={LLeftDown} alt={'flèche en L blanche gauche'} /></RightSide>
    </Container>

    <H3>Points</H3>
    <Container style={{ marginBottom: '-20px' }}>
      <StyledRow justifyContent={'flex-start'} alignItems={'center'} width={'300px'}>
        <span> Points fins</span> <Dots />
      </StyledRow>
      <StyledRow justifyContent={'flex-start'} alignItems={'center'} width={'300px'}>
        <span> Points épais </span> <Dots big />
      </StyledRow>
    </Container>
  </div>
);

export default Elements;
