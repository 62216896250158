import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { colors } from '../../components/styledComponents';
import { H1, H2 } from '../../components/styleGuide/style';
import Comments from '../../components/styleGuide/Comments';
import Colors from '../../components/styleGuide/Colors';
import Fonts from '../../components/styleGuide/Fonts';
import Texts from '../../components/styleGuide/Texts';
import Margins from '../../components/styleGuide/Margins';
import Logos from '../../components/styleGuide/Logos';
import Images from '../../components/styleGuide/Images';
import Elements from '../../components/styleGuide/Elements';
import Buttons from '../../components/styleGuide/Buttons';
import Feedback from '../../components/styleGuide/Feedback';
import Modals from '../../components/styleGuide/Modals';
import Icons from '../../components/styleGuide/Icons';

const Container = styled.div`
  font-family: Roboto;
  margin: 100px;
  color: ${colors.navy};
`;

const StyleGuide = () => (
  <Container>
    <Helmet>
      <title>Tilli : 🎨 Style Guide 📏</title>
    </Helmet>
    <H1>Style Guide de Tilli</H1>
    <Comments />
    <Colors />
    <Fonts />
    <Texts />
    <Margins />
    <Logos />
    <Images />
    <Elements />
    <Buttons />
    <Feedback />
    <Modals />
    <Icons />
    <H2>TODO</H2>
    <ul>
      <li>{'Style d\'écriture: majuscule, voix/ton'}</li>
      <li>Slogans Tilli</li>
      <li>Formulaire : form/input</li>
    </ul>
  </Container>
);

export default StyleGuide;
