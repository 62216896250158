import React from 'react';
import styled from 'styled-components';
import { H2, H3 } from './style';
import { colors, mobileThresholdPixels, Row } from '../../components/Home/v2/StyledComponents';
import { Icon } from '../../components/Home/homeStyledComponents';
import icon1 from '../../assets/1.svg';
import icon2 from '../../assets/2.svg';
import icon3 from '../../assets/3.svg';
import androidV1 from '../../assets/android.svg';
import iOSV1 from '../../assets/iOS.svg';
import facebookV1 from '../../assets/facebook.svg';
import instagramV1 from '../../assets/instagram.svg';
import android from '../../assets/footer/googleplay.svg';
import iOS from '../../assets/footer/appstore.svg';
import facebook from '../../assets/footer/facebook.svg';
import instagram from '../../assets/footer/instagram.svg';
import buste from '../../assets/buste.svg';
import machine from '../../assets/machine.svg';
import heart from '../../assets/heart.svg';
import schedule from '../../assets/schedule.svg';
import euro from '../../assets/euro.svg';
import smile from '../../assets/smile.svg';
import group from '../../assets/group.svg';
import shape from '../../assets/shape.svg';

const Icon2 = styled.span`
  font-size: 36px;
  color: ${colors.navy};
  margin: 4px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 24px;
  }
`;

const Icon3 = styled(Icon)`width: 170px;`;

const Container = styled.div`
  width: fit-content;
  background-color: ${colors.navy};
  margin: 10px;
`;

const Icons = () => (
  <div>
    <H2>Icônes</H2>

    <H3>Set Tilli v2</H3>
    <Row alignItems={'center'}>
      <Container><Icon src={facebook} /></Container>
      <Container><Icon src={instagram} /></Container>
      <Icon3 src={android} />
      <Icon3 src={iOS} />
    </Row>

    <H3>Set Tilli v1</H3>
    <Icon src={icon1} />
    <Icon src={icon2} />
    <Icon src={icon3} />
    <Icon src={androidV1} />
    <Icon src={iOSV1} />
    <Icon src={facebookV1} />
    <Icon src={instagramV1} />
    <Icon src={buste} />
    <Icon src={machine} />
    <Icon src={heart} />

    <H3>Autres</H3>
    <div>
      <Icon src={schedule} />
      <Icon src={euro} />
      <Icon src={smile} />
      <Icon src={group} />
      <Icon src={shape} />
    </div>

    <div>
      <Icon2 className="flaticon-internet" />
      <Icon2 className="flaticon-medical" />
      <Icon2 className="flaticon-shape" />
      <Icon2 className="flaticon-fashion-2" />
      <Icon2 className="flaticon-high-hells" />
      <Icon2 className="flaticon-cap" />
      <Icon2 className="flaticon-winter-hat" />
      <Icon2 className="flaticon-pullover" />
      <Icon2 className="flaticon-christmas-1" />
      <Icon2 className="flaticon-dress-4" />
      <Icon2 className="flaticon-dress-3" />
      <Icon2 className="flaticon-shirt-2" />
      <Icon2 className="flaticon-clothes-4" />
      <Icon2 className="flaticon-coat" />
      <Icon2 className="flaticon-belt" />
    </div>

    <div>
      <Icon2 className="flaticon-clothes-1" />
      <Icon2 className="flaticon-theatre-curtains" />
      <Icon2 className="flaticon-wedding-dress" />
      <Icon2 className="flaticon-shirt-1" />
      <Icon2 className="flaticon-holidays" />
      <Icon2 className="flaticon-jeans" />
      <Icon2 className="flaticon-shorts-1" />
      <Icon2 className="flaticon-backpack" />
      <Icon2 className="flaticon-man" />
      <Icon2 className="flaticon-underwear" />
      <Icon2 className="flaticon-brassiere" />
      <Icon2 className="flaticon-panties" />
      <Icon2 className="flaticon-jacket-4" />
      <Icon2 className="flaticon-shirt" />
      <Icon2 className="flaticon-dress-2" />
    </div>

    <div>
      <Icon2 className="flaticon-hoodie" />
      <Icon2 className="flaticon-hat" />
      <Icon2 className="flaticon-dress-1" />
      <Icon2 className="flaticon-trench-coat" />
      <Icon2 className="flaticon-jacket-3" />
      <Icon2 className="flaticon-jacket-2" />
      <Icon2 className="flaticon-jacket-1" />
      <Icon2 className="flaticon-christmas" />
      <Icon2 className="flaticon-polo" />
      <Icon2 className="flaticon-basketball-jersey" />
      <Icon2 className="flaticon-shorts" />
      <Icon2 className="flaticon-clothes-3" />
      <Icon2 className="flaticon-dress" />
      <Icon2 className="flaticon-overall" />
      <Icon2 className="flaticon-clothes-2" />
      <Icon2 className="flaticon-weather" />
    </div>

    <div>
      <Icon2 className="flaticon-suitcase" />
      <Icon2 className="flaticon-food" />
      <Icon2 className="flaticon-socks" />
      <Icon2 className="flaticon-pijama" />
      <Icon2 className="flaticon-shopping-bag" />
      <Icon2 className="flaticon-summer" />
      <Icon2 className="flaticon-high-heels" />
      <Icon2 className="flaticon-fashion-1" />
      <Icon2 className="flaticon-jacket" />
      <Icon2 className="flaticon-climbing" />
      <Icon2 className="flaticon-clothes" />
      <Icon2 className="flaticon-fashion" />
      <Icon2 className="flaticon-gloves" />
      <Icon2 className="flaticon-skirt" />
      <Icon2 className="flaticon-hand-bag" />
    </div>
  </div>);

export default Icons;
